<template>
  
  <v-container fluid>
<!--     <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "member.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="green lighten-1" dark class="mb-2" @click="exportData">{{ $t( "member.btn_export" ) }}</v-btn>
      <v-btn color="green lighten-1" dark class="mb-2" @click="deployToScanner">{{ $t( "member.btn_deploy" ) }}</v-btn>
      <v-btn color="green lighten-1" dark class="mb-2" @click="removeFromScanner">{{ $t( "member.btn_remove" ) }}</v-btn>
    </v-toolbar> -->

    <v-toolbar flat color="white">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Reservation Actions
          </v-btn>
        </template>

        <v-list>
           <v-list-item link @click="sendKeyInfo">
            <v-list-item-icon>
              <v-icon color="primary">key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send Key Info</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

           <v-list-item link @click="sendReminder">
            <v-list-item-icon>
              <v-icon color="primary">notification_important</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send Reminder</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="exportData">
            <v-list-item-icon>
              <v-icon color="primary">download</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('member.btn_export') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="deployToScanner">
            <v-list-item-icon>
              <v-icon color="primary">video_camera_front</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('member.btn_deploy') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="removeFromScanner">
            <v-list-item-icon>
              <v-icon color="primary">videocam_off</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('member.btn_remove') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub1') }}</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="1">
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.propertyCode"
            :label="$t('reservation.table_property_code')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.internalName"
            :label="$t('reservation.table_listing_name')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            :value="reservation.guideUrl"
            :label="$t('reservation.table_guide_url')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1">
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.guestName"
            :label="$t('reservation.table_guest')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.reservationCode"
            :label="$t('reservation.table_reservation_code')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.memberCount"
            :label="$t('reservation.table_member_count')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.checkInDate"
            :label="$t('reservation.table_checkin_date')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            :value="reservation.checkOutDate"
            :label="$t('reservation.table_checkout_date')"
            readonly
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1">
        </v-col>
        <v-col cols="2">
          <v-switch
            color="primary"
            v-model="reservation.isIdVerified"
            disabled
            :label="$t('reservation.table_member_count')"
          ></v-switch>
        </v-col>
        <v-col cols="2">
          <v-switch
            color="primary"
            v-model="reservation.isKeyNotified"
            disabled
            :label="$t('reservation.table_member_count')"
          ></v-switch>
        </v-col>
        <v-col cols="2">
          <v-switch
            color="primary"
            v-model="reservation.needManualCheck"
            disabled
            :label="$t('reservation.table_need_manual_check')"
          ></v-switch>
        </v-col>
        <v-col cols="4">
          <v-tooltip right v-if="reservation.rejectReason">
            <template v-slot:activator="{ on }">
              <v-chip
                class="custom-chip-width"
                v-on="on"
                v-if="reservation.lastRequestOn"
                :color="getColor(reservation.lastRequestOn, reservation.lastResponse, reservation.rejectReason)"
                dark
                large
              >
                Deploy Failure: {{ reservation.lastRequestOn }}
              </v-chip>
            </template>          
            <span>{{ reservation.rejectReason }}</span>
          </v-tooltip>

          <v-chip
            class="custom-chip-width"
            v-if="!reservation.rejectReason && reservation.lastRequestOn"
            :color="getColor(reservation.lastRequestOn, reservation.lastResponse, reservation.rejectReason)"
            dark
            large
          >
            <div v-if="reservation.lastResponse != reservation.lastRequestOn">Deploying: {{ reservation.lastRequestOn }}</div>
            <div v-else>Deployed On: {{ reservation.lastRequestOn }}</div>
          </v-chip> 
        </v-col>
      </v-row>
    </v-container>
<!--         
        <v-layout align-center justify-space-between row fill-height>
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.phoneNo"
              :label="$t('reservation.table_phone_no')"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.checkInDate"
              :label="$t('reservation.table_checkin_date')"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.checkOutDate"
              :label="$t('reservation.table_checkout_date')"
              disabled
            ></v-text-field>
          </v-flex>
    
            <v-flex grow xs2>
              <v-text-field
                :value="reservation.lastRequestOn"
                :label="$t('reservation.table_last_request')"
                :background-color="getColor(reservation.lastRequestOn, reservation.lastResponse)"
                disabled
              ></v-text-field>
          </v-flex>          
        </v-layout>
 -->
<!--  
      </v-toolbar-items>
    </v-toolbar> -->

    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub1') }}</v-toolbar-title>
    </v-toolbar>

    <amplify-s3-album :key="idAlbumKey+1" :path="idPath" :s3AlbumConfig="imageOption"></amplify-s3-album>
    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub2') }}</v-toolbar-title>
    </v-toolbar>
    
    <amplify-s3-album :key="faceAlbumKey" :path="facePath" :s3AlbumConfig="imageOption"></amplify-s3-album>
    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub4') }}</v-toolbar-title>
    </v-toolbar>

    <amplify-s3-album :key="checkInAlbumKey" :path="checkInPath" :s3AlbumConfig="imageOption"></amplify-s3-album>
    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub3') }}</v-toolbar-title>
    </v-toolbar>

    <v-dialog v-model="dialog" max-width="800px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('member.title_sub4') }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="6">
                  <file-upload 
                    extensions="jpg,jpeg,png" 
                    contentType="image/jpeg,image/png" 
                    :title="$t('member.drop_id')"
                    @filePicked="onIdFilePick"
                    name="dropId"
                    ref="dropId">
                  </file-upload>
                </v-col>
                <v-col cols="6">
                  <file-upload 
                    extensions="jpg,jpeg,png" 
                    contentType="image/jpeg.image/png" 
                    :title="$t('member.drop_face')"
                    @filePicked="onFaceFilePick"
                    name="dropFace"
                    ref="dropFace">
                  </file-upload>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    :color="idIconColor"
                    label
                    text-color="white"
                  >
                    <v-icon>badge</v-icon>
                    Passport
                  </v-chip>
                </v-col>
                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    :color="faceIconColor"
                    label
                    text-color="white"
                  >
                    <v-icon>face_6</v-icon>
                    Portrait
                  </v-chip>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.fullName" 
                    :label="$t('member.label_fullname')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.nationality" 
                    :label="$t('member.label_nationality')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.idNumber" 
                    :label="$t('member.label_id_no')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.phoneNumber" 
                    :label="$t('member.label_phone_no')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menuBirthDate"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.birthDate"
                        :label="$t('member.label_birth_date')"
                        prepend-icon="event"
                        readonly
                        :rules="[rules.required]"
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>                    
                    <v-date-picker v-model="editedItem.birthDate" no-title @input="menuBirthDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.gender" 
                    :label="$t('member.label_gender')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.occupation" 
                    :label="$t('member.label_occupation')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.address" 
                    :label="$t('member.label_address')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.prevPlace" 
                    :label="$t('member.label_prev_place')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="editedItem.nextDest" 
                    :label="$t('member.label_next_dest')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="height: 64px;">
                <v-col cols="6">
                  <template v-if="reservation.smartKeyItems && reservation.smartKeyItems.length > 0">
                    <v-select
                      v-if="reservation.smartKeyItems && reservation.smartKeyItems.length > 0"
                      v-model="editedItem.memberKeyItem"
                      :items="reservation.smartKeyItems"
                      :label="$t('member.label_room_number')"
                      item-text="roomCode"
                      return-object
                    ></v-select>
                  </template>
                  <template v-else>
                    <v-select
                      v-if="listing.staticKeyItems && listing.staticKeyItems.length > 0"
                      v-model="editedItem.memberKeyItem"
                      :items="listing.staticKeyItems"
                      :label="$t('member.label_room_number')"
                      item-text="roomCode"
                      return-object
                    ></v-select>
                  </template>

                  <!-- <v-select
                    v-if="reservation.smartKeyItems && reservation.smartKeyItems.length > 0"
                    v-model="editedItem.memberKeyItem"
                    :items="reservation.smartKeyItems"
                    :label="$t('member.label_room_number')"
                    item-text="roomCode"
                    return-object
                  ></v-select> -->
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-if="editedItem.memberKeyItem"
                    v-model="editedItem.memberKeyItem.keyInfo" 
                    :label="$t('member.label_key_info')"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDetail">Close</v-btn>
            <v-btn color="primary" text @click="saveDetail">Save</v-btn>
          </v-card-actions>                  


        </v-card>
      </v-form>
    </v-dialog>
    <v-data-iterator
      :items="members"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.memberNo"
            cols="12"
            xs="6"
            sm="4"
            md="3"
            lg="2"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                <template v-if="item.fullName">
                  {{ item.fullName }}
                </template>
                <template v-else>
                  {{ $t('member.label_guest') }}
                </template>
                  <v-spacer></v-spacer>
                  <v-btn :color="getMemberColor(item)" fab small dark @click="openDetail(item)">
                    <v-icon>zoom_in</v-icon>
                  </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>



<script>
import Papa from 'papaparse'
import { mapState, mapGetters, mapActions } from 'vuex'
import { CallDialog } from '@/utils'
import store from '@/store'
const FileUpload = () => import('@/components/FileUpload.vue')

export default {
  components: {
    FileUpload,
  },
  data: () => ({
    checkInAlbumKey: 20000,
    faceAlbumKey: 10000,
    idAlbumKey: 0,
    rules: {
      required: value => !!value || 'Value Required',
    },
    pagination: {
      rowsPerPage: 12
    },
    dialog: false,
    menuBirthDate: false,
    editedIndex: -1,
    editedItem: {
      fullName: '',
      gender: '',
      birthDate: '',
      address: '',
      idNumber: '',
      nationality: '',
      occupation: '',
      nextDest: '',
      memberKeyItem: {
        roomCode: '',
        keyInfo: ''
      }
    },
    idImgFile: {},
    faceImgFile: {},
  }),
  async beforeRouteEnter (to, from, next) {
    // console.log('store.state.host', store.state.host)

    const error = await store.dispatch('host/loadMembers').catch(error => {
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  // async beforeRouteUpdate (to, from, next) {
  //   // console.log('store.state.host', store.state.host)

  //   const error = await store.dispatch('host/loadMembers').catch(error => {      
  //     return error
  //   })

  //   if (error) {
  //     CallDialog(error)
  //   } else {
  //     next()
  //   }
  // },
  computed: {
    idIconColor: {
      get() {
        if (this.editedIndex > -1) {
          if (this.members[this.editedIndex].idImgKey) {
            return 'success'
          } else {
            return 'error'
          }
        } else {
          return 'error'
        }
      }
    },
    faceIconColor: {
      get() {
        if (this.editedIndex > -1) {
          if (this.members[this.editedIndex].faceImgKey) {
            return 'success'
          } else {
            return 'error'
          }
        } else {
          return 'error'
        }
      }
    },
    imageOption () {
      return {
        identityId: this.reservation.identityId,
        level: 'private',
      }
    },
    checkInPath () {
      return `${this.reservation.hostId}/listings/${this.reservation.listingId}/${this.reservation.reservationCode}/checkIn`
    },
    facePath () {
      return `${this.reservation.hostId}/listings/${this.reservation.listingId}/${this.reservation.reservationCode}/face`
    },
    idPath () {
      return `${this.reservation.hostId}/listings/${this.reservation.listingId}/${this.reservation.reservationCode}/id`
    },
    ...mapState({
      reservation: state => state.host.reservation,
      members: state => state.host.members,
      scannerInstallations: state => state.scanner.scannerInstallations,
      reservationRooms: state => state.host.reservationRooms,
      faceImgKeyCount: state => state.host.faceImgKeyCount,
      listing: state => state.host.listing
    }),
    ...mapGetters('host', {
      allIdVerified: 'allIdVerified',
    })
  },
  methods: {
    // idIconColor(memberItem) {
    //   console.log('idIconColor this.editedIndex', this.editedIndex)
    //     if (memberItem) {
    //       if (memberItem.idImgKey) {
    //         return 'success'
    //       } else {
    //         return 'error'
    //       }
    //     } else {
    //       return 'error'
    //     }
    // },
    getMemberColor(memberItem) {
      if (this.memberInfoFilled(memberItem)) {
        return 'success'
      } else {
        return 'error'
      }
    },
    memberInfoFilled(memberItem) {
      if (!memberItem.fullName) {
        console.log('fullName false', memberItem.fullName)
        return false
      }

      if (!memberItem.nationality) {
        console.log('nationality false', memberItem.nationality)
        return false
      }

      if (!memberItem.idNumber) {
        console.log('idNumber false', memberItem.idNumber)
        return false
      }

      if (!memberItem.phoneNumber) {
        console.log('phoneNumber false', memberItem.phoneNumber)
        return false
      }

      if (!memberItem.birthDate) {
        console.log('birthDate false', memberItem.birthDate)
        return false
      }

      if (!memberItem.gender) {
        console.log('gender false', memberItem.gender)
        return false
      }

      if (!memberItem.occupation) {
        console.log('occupation false', memberItem.occupation)
        return false
      }

      if (!memberItem.address) {
        console.log('address false', memberItem.address)
        return false
      }


      if (!memberItem.prevPlace) {
        console.log('prevPlace false', memberItem.prevPlace)
        return false
      }

      if (!memberItem.nextDest) {
        console.log('nextDest false', memberItem.nextDest)
        return false
      }

      if (!memberItem.faceImgKey) {
        console.log('faceImgKey false', memberItem.faceImgKey)
        return false
      }

      if (!memberItem.idImgKey) {
        console.log('idImgKey false', memberItem.idImgKey)
        return false
      }
      
      return true
    },
    async saveDetail() {
      const valid = this.$refs.form.validate()

      const error1 = await this.updateMember(this.editedItem).catch(e => {
        console.error('updateMember', e)
        return e
      })
      if (error1) {
        CallDialog(error1)
      }

      // const error2 = await this.putImage({member: this.editedItem, idImgFile: this.idImgFile, faceImgFile: this.faceImgFile}).catch(e => {
      //   console.error('putImage', e)
      //   return e
      // })
      // if (error2) {
      //   CallDialog(error2)
      // }

      if (this.reservation.isIdVerified != this.allIdVerified) {
        this.reservation.isIdVerified = this.allIdVerified
        const error3 = await this.updateIdVerified(this.reservation).catch(e => {
          console.error('updateIdVerified', e)
          return e
        })
        if (error3) {
          CallDialog(error3)
        }
      }
      

      this.$refs.dropId.clear()
      this.$refs.dropFace.clear()

      setTimeout(() => {
        this.dialog = false

        this.faceAlbumKey += 1
        this.idAlbumKey += 1
      }, 10)
    },
    closeDetail() {
      this.$refs.dropId.clear()
      this.$refs.dropFace.clear()
      // this.dialog = false

      const valid = this.$refs.form.validate()

      setTimeout(() => {
        this.dialog = false

        // this.faceAlbumKey += 1
        // this.idAlbumKey += 1        
      }, 10)
    },
    openDetail(item) {
      if (this.$refs.dropId) {
        this.$refs.dropId.clear()
      }

      if (this.$refs.dropFace) {
        this.$refs.dropFace.clear()
      }

      console.log('openDetail', item)
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    exportData() {
      const exportJson = this.members.map((member) => {
        return {
          "フルネーム": member.fullName,
          "性別": member.gender,
          "生年月日": member.birthDate,
          "住所": member.address,
          "電話番号": member.phoneNumber,
          "身分証明書番号": member.idNumber,
          "国籍": member.nationality,
          "到着日付": this.reservation.checkInDate,
          "出発日時": this.reservation.checkOutDate,
          "前泊地": member.prevPlace,
          "行先地": member.nextDest,
          "ビル名": this.reservation.internalName,
          "室名": member.memberKeyItem.roomCode,
          "職業": member.occupation
        }
      })

      console.log('exportJson', exportJson)

      const csv = Papa.unparse(exportJson)

      const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      let csvURL
      if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, 'download.csv')
      } else {
          csvURL = window.URL.createObjectURL(csvData)
      }
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', 'download.csv')
      tempLink.click()
    },
    async onIdFilePick(file) {
      console.log('onIdFilePick', file)

      const error = await this.putImage({member: this.editedItem, imgFile: file, isId: true}).catch(e => {
        console.error('putImage', e)
        return e
      })
      if (error) {
        CallDialog(error)
      }
      console.log('this.members', this.members)
      console.log('this.editedItem', this.editedItem)
    },
    //TODO remove checkInImgKey for new face
    async onFaceFilePick(file) {
      console.log('onFaceFilePick', file)
      
      const error = await this.putImage({member: this.editedItem, imgFile: file, isId: false}).catch(e => {
        console.error('putImage', e)
        return e
      })
      if (error) {
        CallDialog(error)
      }
      console.log('this.members', this.members)
      console.log('this.editedItem', this.editedItem)
    },
    async sendKeyInfo() {
      await this.notifyGuest({reservation: this.reservation, messageType: 'KEYINFO'})
    },
    async sendReminder() {
      await this.notifyGuest({reservation: this.reservation, messageType: 'REMINDER'})
    }, 
    async removeFromScanner() {
      try {
        const cancelResult = await this.resetScannerDeployment({
          reservationCode: this.reservation.reservationCode, 
          listingId: this.reservation.listingId,
          propertyCode: this.reservation.propertyCode
        })

        cancelResult.lastUpdateOn = this.reservation.lastUpdateOn

        await this.updateReservationStatus(cancelResult)

      } catch(err) {
        console.error('removeFromScanner', err)
        CallDialog(err)
        throw err
      }


    },    
    async deployToScanner() {

      try {

        await this.validateScanners(this.reservation.propertyCode)

        await this.validateFaceImgs()

        const deployResult = await this.dispatchScannerDeployment({
          reservationCode: this.reservation.reservationCode, 
          listingId: this.reservation.listingId,
          propertyCode: this.reservation.propertyCode
        });
        
        deployResult.lastUpdateOn = this.reservation.lastUpdateOn

        await this.updateReservationStatus(deployResult)

      } catch(err) {
        console.error('dispatchScannerDeployment', err)
        CallDialog(err)
        throw err
      }

    },
    getColor (lastRequestOn, lastResponse) {
      if (lastRequestOn == lastResponse) {
        return 'green'
      } else {
        return 'orange'
      }        

    },
    ...mapActions({
      updateMember: 'host/updateMember',
      putImage: 'host/putImage',
      validateFaceImgs: 'host/validateFaceImgs',
      validateScanners: 'scanner/validateScanners',
      dispatchScannerDeployment: 'scanner/dispatchScannerDeployment',
      resetScannerDeployment: 'scanner/resetScannerDeployment',
      updateReservationStatus: 'host/updateReservationStatus',
      updateIdVerified: 'host/updateIdVerified',
      notifyGuest: 'host/notifyGuest',
    })
  },
}
</script>

<style>
#app .amplify-album { 
  width: 100%;
  margin: 0 auto;
  padding: 0.1em;
  border-radius: 0px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}

#app .amplify-album .amplify-album-container { 
  width: 100%;
  margin: 0 auto;
  padding: 0.1em;
}

#app .amplify-album .amplify-album-container .amplify-image {
  width: 8.33%;
  margin: 0 auto;
  padding: 0.1em;
  border-radius: 6px;
  border: 2px solid var(--color-white);
  cursor: pointer;
}
</style>

<style scoped>
.v-text-field >>> input {
  font-size: 1.5em;
}

.v-text-field >>> label {
  font-size: 1.3em;
}

.v-chip >>> span {
  font-size: 1.3em;
}

.custom-chip-width {
  width: 600px; /* Set your desired width here */
}
</style>

